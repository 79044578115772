import httpService from "../helpers/http.service";

export default {
   login(payload){
       return httpService.post('auth/login', payload);
   },
    reset(payload){
       return httpService.post('auth/olvidoclave ', payload);
   },
   logout(payload){
       return httpService.get('auth/logout', payload);
   },
   register(payload){
       return httpService.post('auth/register', payload);
   },
    update(payload){
       return httpService.post('auth/update', payload);
   },
    updatePassword(payload){
       return httpService.post('auth/clave', payload);
   },
    getUserProfile(){
       return httpService.get('auth/show');
   }
};
