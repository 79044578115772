import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/home",
            component: () => import("@/views/theme/Base"),
            children: [
                {
                    path: "/home",
                    name: "home",
                    component: () => import("@/views/pages/Dashboard.vue")
                },
                {
                    path: "/plan",
                    name: "plan",
                    component: () => import("@/views/pages/Plan.vue")
                },
                {
                    path: "users",
                    name: "users",
                    component: () => import("@/views/pages/users/Users.vue"),
                    children: [
                        {
                            path: "",
                            component: () => import("@/views/pages/users/UsersList.vue")
                        },
                    ]
                },
                {
                    path: "/contract",
                    name: "contract",
                    component: () => import("@/views/pages/contract/Contract.vue"),
                    children: [
                        {
                            path: "register",
                            name: "contract-register",
                            component: () => import("@/views/pages/contract/ContractRegister.vue")
                        },
                        {
                            path: "register/:id",
                            name: "contract-edit",
                            component: () => import("@/views/pages/contract/ContractRegister.vue")
                        },
                        {
                            path: "list",
                            name: "list",
                            component: () => import("@/views/pages/contract/ContractList.vue")
                        },
                        {
                            path: "select",
                            name: "contract-select",
                            component: () => import("@/views/pages/contract/ContractAvailable.vue")
                        }
                    ]
                },
                {
                    path: 'profile',
                    name: 'profile',
                    component: () => import("@/views/pages/auth/Profile.vue")
                },

                {
                    path: "transaction",
                    name: "transaction-index",
                    component: () => import("@/views/pages/transactions/Transaction.vue"),
                    children: [
                        {
                            path: "register/:id",
                            name: "transaction-register",
                            component: () => import("@/views/pages/transactions/TransactionRegister.vue")
                        },
                        {
                            path: "",
                            name: "transaction",
                            component: () => import("@/views/pages/transactions/TransactionHistory.vue")
                        }
                    ]
                },
                {
                    path: "payment",
                    name: "payment-index",
                    component: () => import("@/views/pages/payment/Payment.vue"),
                    children: [
                        {
                            path: "preview",
                            name: "preview",
                            component: () => import("@/views/pages/payment/Preview.vue")
                        },
                        {
                            path: "result",
                            name: "result",
                            component: () => import("@/views/pages/payment/Result.vue")
                        },
                        {
                            path: "",
                            name: "payment",
                            component: () => import("@/views/pages/payment/PaymentList.vue")
                        }
                    ]
                }
            ]
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/views/pages/error/Error.vue"),
            children: [
                {
                    path: "error-1",
                    name: "error-1",
                    component: () => import("@/views/pages/error/Error-1.vue")
                },
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/views/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/views/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/views/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/views/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/views/pages/error/Error-6.vue")
                }
            ]
        },
        {
            path: "/",
            component: () => import("@/views/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/views/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/views/pages/auth/Register")
                },
                {
                    name: "reset",
                    path: "/reset",
                    component: () => import("@/views/pages/auth/ResetPassword")
                }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/views/pages/error/Error-1.vue")
        }
    ]
});
