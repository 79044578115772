
import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import loginService from "@/service/login.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const RESET = "reset";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const UPDATE_PASSWORD = "updatePassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const INIT_LOGIN = "initLogin";
export const SET_UPDATE = "updateProfile";
// getters

export const GET_USER = "currentUser";

const state = {
  errors: undefined,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  loading: false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    context.commit(INIT_LOGIN);
    return new Promise((resolve, reject) => {
      loginService.login(credentials).then(( data ) => {
      context.commit(SET_AUTH, data.data);
      resolve(data.data.data);
      }).catch(( {response} ) => {
          if(response)
            context.commit(SET_ERROR, response.data.errors ?? [response.data.message]);
            reject(response);
      });
    });
  },
  [RESET](context, credentials) {
    context.commit(INIT_LOGIN);
    return new Promise((resolve, reject) => {
      loginService.reset(credentials).then(( data ) => {
      resolve(data.data);
      }).catch(( {response} ) => {
          if(response)
            context.commit(SET_ERROR, response.data.errors ?? [response.data.message]);
            reject(response);
      });
    });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      if(JwtService.getToken())
      {
        loginService.logout().then(() => {
          resolve();
        }).catch(( {response} ) => {
          reject(response);
        }).finally(()=> context.commit(PURGE_AUTH));
      }
      else {
         reject();
      }
    });
  },
  [REGISTER](context, credentials) {
     context.commit(INIT_LOGIN);
      return new Promise((resolve, reject) => {
        loginService.register(credentials).then(( data ) => {
          context.commit(SET_AUTH, data.data);
          resolve();
        }).catch(( {response} ) => {
          if(response)
            context.commit(SET_ERROR, response.data.errors ?? [response.data.message]);
          reject(response);
        });
      });
  },
  [UPDATE_USER](context, credentials) {
      return new Promise((resolve, reject) => {
        loginService.update(credentials).then(( data ) => {
          context.commit(SET_UPDATE, data.data);
          resolve();
        }).catch(( {response} ) => {
          if(response)
            context.commit(SET_ERROR, response.data.errors ?? [response.data.message]);
          reject(response);
        });
      });
  },
  [UPDATE_PASSWORD](context, credentials) {
      return new Promise((resolve, reject) => {
        loginService.updatePassword(credentials).then(( data ) => {
          resolve(data.data);
        }).catch(( {response} ) => {
          if(response)
            context.commit(SET_ERROR, response.data.errors ?? [response.data.message]);
          reject(response);
        });
      });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
    state.loading = false
  },
  [INIT_LOGIN](state) {
    state.isAuthenticated = false;
    state.user = undefined;
    state.errors = undefined;
    state.loading = true;
    JwtService.destroyToken();
  },

  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user.data;
    state.errors = undefined;
    state.loading = false;
    JwtService.saveToken(user);

  },
  [SET_UPDATE](state, user) {
    state.isAuthenticated = true;
    state.user = user.data;
    state.errors = undefined;
    state.loading = false;
    JwtService.updateUser(state.user);

  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.loading = false;
    state.user = {};
    state.errors = undefined;
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
