import axios from 'axios';
import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";
import router from '../router';
/**
 *
 * @type {{headers: {Accept: string, "Content-Type": string}, baseURL}}
 */
const config = {
    baseURL: API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
};

const httpClient = axios.create(config);

/**
 * @param config
 * @returns {*}
 */
const authInterceptor = config => {
    /** add auth token */
    const token = JwtService.getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
};


const loggerInterceptor = config => {
    return config;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

/** Adding the response interceptors */
httpClient.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if(error.response.status === 401){
            router.push({ name: "login" })
        }
        return Promise.reject(error);
    }
);

export default httpClient
